import React from 'react';

import { CAREER_PLAN_STATUSES, CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { difference, uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import { SearchSelect } from '~/components/SearchSelect';
import SelectJobProfileModal from '~/components/SelectJobProfileModal';
import ToolTip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';
import { ActiveRole } from '~/pages/PassportPage/types';
import type { IEmployee } from '~/pages/Reviews/EditCycle/ReviewCycleForm/types';

import { Actions, Content, Footer, Header, Label, Title } from './design';
import { useEditEmployeeModal } from './useEditEmployeeModal';

import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getSettingsRole, getUser, getUsers } from '~/selectors/baseGetters';
import { createCareerPlans } from '~/services/careerPlans';
import { addMemberToTeams } from '~/store/teams/actions';
import getUserFullName from '~/utils/getUserFullName';

import { AssignTeamsModal } from '../AssignTeamsModal';
import { confirm } from '../ConfirmationModal/confirm';

import type { IJobProfile, ITeam, IUser } from '@learned/types';

interface EditEmployeeModalProps {
  onClose: () => void;
  onSave: (employee: IEmployee) => void;
  employee: IEmployee;
  options: {
    showCoaches: boolean;
    showJobs: boolean;
  };
  isDraft?: boolean;
}

const EditEmployeeModal = ({
  onClose,
  onSave,
  employee,
  options,
  isDraft,
}: EditEmployeeModalProps) => {
  const dispatch = useDispatch();
  const { i18n } = useLingui();
  const $isAssignTeamModalOpen = useBoolState(false);
  const $isAssignJobModalOpen = useBoolState(false);
  const getMultiLangString = useMultiLangString();
  const { formMethods } = useEditEmployeeModal({ employee });

  const userRole = useSelector(getSettingsRole);
  const currentUser = useSelector(getUser);
  const companyUsers: { [key: string]: IUser } = useSelector(getUsers);

  const { watch, setValue } = formMethods;

  const isPublishedUserReview = !!employee.userReview && !isDraft;

  const onCoachesChange = async (selected: IUser[]) => {
    // @ts-ignore
    const removedItems = difference(watch('coaches.selected'), selected);

    if (removedItems.length > 0 && isPublishedUserReview && !isDraft) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.WARNING,
        title: i18n._(t`Delete coach?`),
        description: i18n._(
          t`Are you sure you want to delete this coach from the review? Provided input will be lost. This cannot be undone.`,
        ),
      });
      if (!isConfirmed) {
        return;
      }
    }

    setValue('coaches.selected', selected);
  };

  const handleAssignTeamClick = () => {
    $isAssignTeamModalOpen.on();
  };

  const handleAssignJobClick = () => {
    $isAssignJobModalOpen.on();
  };

  const handleAddUserToTeam = async (selectedTeams: ITeam[]) => {
    const teams = selectedTeams.map(({ id }) => id).filter(Boolean);

    await dispatch(
      addMemberToTeams(employee.id, uniq(teams), true, {
        sendRequestAsAdmin: (currentUser as unknown as IUser).isAdmin,
      }),
    );

    const newCoachIdList = selectedTeams
      .map(({ coaches }) => coaches)
      .flat()
      .filter((coachId) => coachId !== employee.id);

    const newCoaches = newCoachIdList.map((coach: string) => companyUsers[coach]);

    setValue('coaches.filtered', uniq([...watch('coaches.filtered'), ...newCoaches]));

    setValue('guests.selected', uniq([...watch('guests.selected'), ...newCoaches]));

    $isAssignTeamModalOpen.off();
  };

  const updateCareerPlansInModal = async (selectedJobProfiles: IJobProfile[]) => {
    setValue('jobProfiles.filtered', [...watch('jobProfiles.filtered'), ...selectedJobProfiles]);
    setValue('jobProfiles.selected', [...watch('jobProfiles.selected'), ...selectedJobProfiles]);
  };

  const handleAddJobToUser = async (selectedJobProfiles: ActiveRole[]) => {
    const jobsData = selectedJobProfiles.map((job) => ({
      jobProfileId: job.id,
      status: CAREER_PLAN_STATUSES.CURRENT,
      userId: employee.id,
    }));

    await createCareerPlans(jobsData, userRole);
    updateCareerPlansInModal(selectedJobProfiles as unknown as IJobProfile[]);
    $isAssignTeamModalOpen.off();
  };

  return (
    <Modal
      width={500}
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      hideFooter
      isHideHeader
      centerModal
      showDivider={false}
      borderRadius={6}
    >
      <Header>
        <Title>
          <Trans>Edit employee</Trans>
        </Title>
        <Button size={ButtonSize.BIG} onClick={onClose} variant={ButtonVariant.CLOSE} />
      </Header>
      <Content>
        <UserAvatar userId={employee.id} />
        {options.showJobs && (
          <ToolTip
            tooltip={i18n._(
              t`After a review cycle has been published, the selected job(s) cannot be changed. Remove user from and add to cycle again to select jobs.`,
            )}
            disabled={!isPublishedUserReview}
          >
            <div className="inputWrapper">
              <Label>
                <Trans>Select job(s) to review</Trans>
              </Label>
              <SearchSelect
                selectedItems={watch('jobProfiles.selected')}
                onChange={(selected) => setValue('jobProfiles.selected', selected)}
                onSearchChange={(search) => setValue('jobProfiles.search', search)}
                items={watch('jobProfiles.filtered')}
                stringifyItem={(job) => getMultiLangString(job.name)}
                placeholder={i18n._(t`Search job(s)`)}
                isExpandable
                disabled={isPublishedUserReview}
                noResultsPlaceholder={i18n._(t`No job assigned`)}
                actions={
                  (currentUser as unknown as IUser).isAdmin
                    ? [
                        {
                          handler: handleAssignJobClick,
                          name: i18n._(t`Assign job`),
                          icon: ICONS.ADD_PLUS,
                        },
                      ]
                    : []
                }
              />
            </div>
          </ToolTip>
        )}
        {options.showCoaches && (
          <div className="inputWrapper">
            <Label>
              <Trans>Select coach(es) who will provide</Trans>
            </Label>
            <SearchSelect
              selectedItems={watch('coaches.selected')}
              onChange={onCoachesChange}
              onSearchChange={(search) => setValue('coaches.search', search)}
              items={watch('coaches.filtered')}
              stringifyItem={(user) => getUserFullName(user)}
              placeholder={i18n._(t`Search coach(es)`)}
              isExpandable
              showAvatar
              noResultsPlaceholder={i18n._(t`No team assigned`)}
              actions={
                (currentUser as unknown as IUser).isAdmin
                  ? [
                      {
                        handler: handleAssignTeamClick,
                        name: i18n._(t`Assign team`),
                        icon: ICONS.ADD_PLUS,
                      },
                    ]
                  : []
              }
            />
          </div>
        )}
        <div className="inputWrapper">
          <Label>
            <Trans>Select guests invited to the review</Trans>
          </Label>
          <SearchSelect
            selectedItems={watch('guests.selected')}
            onChange={(selected) => setValue('guests.selected', selected)}
            onSearchChange={(search) => setValue('guests.search', search)}
            items={watch('guests.filtered')}
            stringifyItem={(user) => getUserFullName(user)}
            placeholder={i18n._(t`Search guests`)}
            minCharsToShowDropdown={2}
            isExpandable
            showAvatar
          />
        </div>
      </Content>
      <Footer>
        <Actions>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.TEXT_PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={onClose}
          />
          <Button
            label={i18n._(t`Save`)}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            onClick={() => {
              onSave({
                ...employee,
                jobProfiles: watch('jobProfiles.selected'),
                coaches: watch('coaches.selected').map(({ id }) => id),
                guests: watch('guests.selected').map(({ id }) => id),
              });
              onClose();
            }}
          />
        </Actions>
      </Footer>
      {$isAssignTeamModalOpen.value && (
        <AssignTeamsModal
          employee={employee as unknown as IUser}
          onClose={$isAssignTeamModalOpen.off}
          onSubmit={handleAddUserToTeam}
        />
      )}
      {$isAssignJobModalOpen.value && (
        <SelectJobProfileModal
          title={i18n._(t`Assign job`)}
          onModalClose={$isAssignJobModalOpen.off}
          selectedUserId={employee.id}
          onSubmit={handleAddJobToUser}
        />
      )}
    </Modal>
  );
};

export { EditEmployeeModal };
