import React from 'react';

import { ROLES } from '@learned/constants';
import { ITeam } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { uniq } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { ButtonVariant } from '~/components/Buttons';
import { AssignTeamsModal } from '~/components/Modals/AssignTeamsModal';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SectionBodyProps } from '~/pages/PassportPage/types';

import { SectionHeader } from './SectionHeader';
import { SectionList } from './SectionList';

import zeroState from '~/assets/no-result.webp';

import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { getCurrentCompanyId, getUsers } from '~/selectors/baseGetters';
import { removeMemberFromTeam, addMemberToTeams } from '~/store/teams/actions';
import getUserTeams from '~/utils/getUserTeams';
import history from '~/utils/history';

const StateImg = styled.img`
  width: 75px;
  height: auto;
`;

const SectionUserTeams = ({ userId, teamsState: teams, fetching, isAdmin }: SectionBodyProps) => {
  const { i18n } = useLingui();
  const $modal = useBoolState();
  const $loading = useBoolState();
  const dispatch = useDispatch();
  const currentCompanyId = useSelector(getCurrentCompanyId);
  const users = useSelector(getUsers);

  const params = useParams();

  const addUserToTeam = async (selectedTeams: ITeam[]) => {
    $loading.on();

    const teams = selectedTeams.map(({ id }) => id).filter(Boolean);

    await dispatch(
      addMemberToTeams(userId, uniq(teams), true, {
        sendRequestAsAdmin: isAdmin,
      }),
    );

    $loading.off();
    $modal.off();
  };

  const removeUserFromTeam = async (teamId: string) => {
    $loading.on();
    await dispatch(removeMemberFromTeam(teamId, userId, { sendRequestAsAdmin: isAdmin }));

    // @ts-ignore
    if (params && params.teamId === teamId) {
      history.push(
        routes.TEAM_COACH.build({
          role: ROLES.COACH,
          teamId,
          companyId: currentCompanyId,
        }),
      );
    }

    $loading.off();
  };

  const userTeams = teams ? getUserTeams(userId, Object.values(teams)) : [];

  return (
    <div>
      <SectionHeader
        title={i18n._(t`Teams`)}
        description={''}
        actionLabel={isAdmin ? i18n._(t`Assign`) : undefined}
        actionOnClick={isAdmin ? $modal.on : undefined}
        actionButtonVariant={isAdmin ? ButtonVariant.SECONDARY : undefined}
      />
      <ShowSpinnerIfLoading loading={fetching || $loading.value || false}>
        <SectionList
          items={userTeams}
          itemActionLabel={i18n._(t`Remove`)}
          itemActionOnClick={removeUserFromTeam}
          itemActionLoading={$loading.value}
          placeholder={
            <Placeholder
              title={i18n._(t`No teams`)}
              subTitle={''}
              Icon={() => <StateImg alt="noTeams" src={zeroState} />}
            />
          }
          isCoachAdmin={isAdmin}
        />
      </ShowSpinnerIfLoading>

      {$modal.value && (
        <AssignTeamsModal employee={users[userId]} onClose={$modal.off} onSubmit={addUserToTeam} />
      )}
    </div>
  );
};

export { SectionUserTeams };
