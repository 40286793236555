import React, { useState } from 'react';

import { SURVEY_QUESTION_TYPES, SURVEY_THEME_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import size from 'lodash/size';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { ButtonPrimary, ButtonShadow, ButtonWhite } from '~/components/Button';
import { IconOld } from '~/components/IconOld';
import Dot6Icon from '~/components/Icons/Dot6';
import { PreviewQuestionModal } from '~/components/Modals/PreviewQuestionModal';
import { QuestionModal } from '~/components/Modals/QuestionModal';
import { Footer as PreviewQuestionCardFooter } from '~/components/Question/Footer';
import { PreviewQuestionCard } from '~/components/Question/PreviewQuestionCard';

import emptyState from '~/assets/no-result.webp';

import type { IQuestion, IQuestionForm } from '~/@types/question';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import {
  copySurveyQuestion,
  createSurveyQuestion,
  updateSurveyQuestion,
  deleteSurveyQuestion,
} from '~/services/surveyQuestions';
import { COLORS } from '~/styles';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { convertQuestionOptions } from '~/utils/questions';
import { turnArrayIntoMultiLang, turnMultiLangIntoArray } from '~/utils/turnMultiLangIntoArray';

import { BackButton, PublishButton } from '../design';

import type { IGeneralForm } from '../types';
import type { ISurveyQuestionDefaultData } from '@learned/types';
import type { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

interface IQuestionsProps {
  themeId?: string;
  setCurrentSection: (index: number) => void;
  generalFormMethods: UseFormReturn<IGeneralForm>;
  formMethods: UseFieldArrayReturn<IGeneralForm, 'questions', 'key'>;
  languageState: ILanguageStateReturn;
  onSubmit: (status: SURVEY_THEME_STATUS) => void;
  onPublish: () => void;
  isPublishDisabled: boolean;
  updateLastSavedTime: () => void;
  handleUpdateTheme: () => void;
  triedToSubmit: boolean;
}

const Wrapper = styled.div`
  border-radius: 10px;
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 32px 40px;
`;

const Title = styled.h2`
  font-size: 26px;
  color: ${COLORS.BLACK};
  font-weight: normal;
  margin-top: 0;
`;

const CreateFirstQuestion = styled(ButtonPrimary)`
  height: 32px;
  min-width: 159px;
  border-radius: 100px;
  font-size: 12px;
  margin: 68px auto 0;
`;

const CreateNewQuestionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 7px 0;
  justify-content: center;
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
  font-size: 12px;
`;

const CreateNewQuestion = styled(ButtonWhite)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 7px 0;
  height: 38px;
  min-width: 138px;
  border-radius: 6px;
  border: 1px solid ${COLORS.BORDER_HARD};
  font-size: 12px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: #909cc2;
`;

const List = styled.div`
  margin: 40px;
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 57px auto 0;

  & img {
    margin-right: 20px;
  }

  & .description {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    width: 182px;
    color: #909cc2;
    margin-bottom: 34px;
  }
`;

const AddQuestion = styled(ButtonShadow)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 41px;
  border-radius: 6px;
  padding: 0;
  border: solid 1px ${COLORS.BORDER_HARD};
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
`;

const HoverIcon = styled.div<{ isVisible?: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 10px;
  left: -36px;
`;

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  & .question {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover ${HoverIcon} {
      visibility: visible;
    }
  }

  & .newQuestion {
    position: relative;
    height: 10px;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    & ${AddQuestion} {
      position: absolute;
      display: none;
      right: -42px;
      top: -16px;
    }

    &:hover {
      background-color: ${COLORS.BG_PAGE};

      & ${AddQuestion} {
        display: flex;
      }
    }
  }
`;

const Questions = ({
  themeId,
  languageState,
  formMethods,
  setCurrentSection,
  generalFormMethods,
  onPublish,
  isPublishDisabled,
  updateLastSavedTime,
  handleUpdateTheme,
}: IQuestionsProps) => {
  const { i18n } = useLingui();
  const { fields, move, remove, insert, update } = formMethods;
  const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
  const [isEditQuestionModalOpen, setIsEditQuestionModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<IQuestion>();
  const [indexItemToEdit, setIndexItemToEdit] = useState<number>();
  const [indexItemToAdd, setIndexItemToAdd] = useState<number>(0);
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  const createQuestion = async (value: IQuestionForm) => {
    const starsData =
      value.type === SURVEY_QUESTION_TYPES.STARS
        ? {
            firstLabel: convertLanguageValue(value.options[0].label),
            lastLabel: convertLanguageValue(last(value.options)?.label),
            size: size(value.options),
          }
        : undefined;

    if (themeId) {
      const {
        data: { surveyQuestion },
      } = await createSurveyQuestion({
        position: indexItemToAdd,
        // @ts-ignore
        question: {
          theme: themeId,
          type: value.type as SURVEY_QUESTION_TYPES,
          name: convertLanguageValue(value.name),
          data: {
            options: value?.options?.map(({ icon, label }) => ({
              icon,
              label: convertLanguageValue(label),
            })),
            ...starsData,
            isCommentsAllowed: value.settings.isCommentsAllowed,
            isCommentsObligated: value.settings.isCommentsObligated,
            isMeasurementReversed: value.settings.isMeasurementReversed,
          },
        },
      });
      insert(indexItemToAdd, {
        ...value,
        id: surveyQuestion.id,
      });
      updateLastSavedTime();
    }
  };

  const updateQuestion = async (data: IQuestionForm, index: number) => {
    if (data.id) {
      const starsData =
        data.type === SURVEY_QUESTION_TYPES.STARS
          ? {
              firstLabel: convertLanguageValue(
                data.options[0].label.filter((langValue) => !!langValue?.value),
              ),
              lastLabel: convertLanguageValue(
                last(data.options)?.label.filter((langValue) => !!langValue?.value),
              ),
              size: size(data.options),
            }
          : undefined;

      await updateSurveyQuestion(data.id, {
        type: data.type as SURVEY_QUESTION_TYPES,
        name: turnArrayIntoMultiLang(data.name.filter((langValue) => langValue.value !== '')),
        data:
          data.type !== SURVEY_QUESTION_TYPES.TEXT
            ? {
                options: data?.options?.map(({ icon, label }) => ({
                  icon,
                  label: turnArrayIntoMultiLang(
                    label.filter((langValue) => langValue.value !== ''),
                  ),
                })),
                ...starsData,
                isCommentsAllowed: data.settings.isCommentsAllowed,
                isCommentsObligated: data.settings.isCommentsObligated,
                isMeasurementReversed: data.settings.isMeasurementReversed,
              }
            : undefined,
      });
      update(index, data);
      updateLastSavedTime();
    }
  };

  async function duplicateQuestion(id: IQuestionForm['id'], index: number) {
    if (id) {
      const {
        data: { surveyQuestion },
      } = await copySurveyQuestion(id);
      insert(index, {
        id: surveyQuestion.id,
        name: turnMultiLangIntoArray(surveyQuestion.name, languageState.companyLanguages),
        type: surveyQuestion.type,
        settings: {
          isCommentsAllowed: (surveyQuestion.data as ISurveyQuestionDefaultData).isCommentsAllowed,
          isCommentsObligated: (surveyQuestion.data as ISurveyQuestionDefaultData)
            .isCommentsObligated,
          isMeasurementReversed: (surveyQuestion.data as ISurveyQuestionDefaultData)
            .isMeasurementReversed,
        },
        options: convertQuestionOptions(surveyQuestion, languageState.companyLanguages),
      });
      updateLastSavedTime();
    }
  }

  const createNewQuestion = () => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(size(fields));
  };

  const insertNewQuestion = (index: number) => {
    setIsCreateQuestionModalOpen(true);
    setIndexItemToAdd(index + 1);
  };

  const isDraft = generalFormMethods.watch('status') === SURVEY_THEME_STATUS.DRAFT;

  return (
    <Wrapper>
      <Title>{i18n._(t`Questions`)}</Title>
      <div>
        {size(fields) ? (
          <div>
            <Description>
              <Trans id="Add questions to your theme and import these questions into your surveys. You can also add, edit or delete questions later. Drag questions in the desired order." />
            </Description>
            <List>
              <DragDropContext
                onDragEnd={(result) => {
                  move(result.source.index, result?.destination?.index || 0);
                  handleUpdateTheme();
                }}
              >
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map(({ key, id, name, type, ...rest }, idx) => (
                        <Draggable key={key} draggableId={key} index={idx}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <CardWrapper>
                                <div className="question">
                                  <HoverIcon isVisible={snapshot.isDragging}>
                                    <Dot6Icon size={32} />
                                  </HoverIcon>
                                  {/* @ts-ignore */}
                                  <PreviewQuestionCard
                                    languageState={languageState}
                                    onClickTitle={() => {
                                      setIsPreviewModalVisible(true);
                                      setPreviewIndex(idx);
                                    }}
                                    index={idx}
                                    type={type}
                                    name={name}
                                    isActive={snapshot.isDragging}
                                  >
                                    <PreviewQuestionCardFooter
                                      onDelete={() => {
                                        id && deleteSurveyQuestion(id);
                                        remove(idx);
                                        updateLastSavedTime();
                                      }}
                                      onDuplicate={() => duplicateQuestion(id, idx + 1)}
                                      onEdit={() => {
                                        setItemToEdit({ id, name, type, ...rest });
                                        setIsEditQuestionModalOpen(true);
                                        setIndexItemToEdit(idx);
                                      }}
                                      isEditButtonVisible
                                    />
                                  </PreviewQuestionCard>
                                </div>
                                {size(fields) - 1 > idx && (
                                  <button
                                    className="newQuestion"
                                    onClick={() => insertNewQuestion(idx)}
                                  >
                                    <AddQuestion>
                                      <IconOld name="AddCircle" width={14} height={14} />
                                    </AddQuestion>
                                  </button>
                                )}
                              </CardWrapper>
                            </div>
                          )}
                        </Draggable>
                      ))}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <CreateNewQuestionWrapper>
                <CreateNewQuestion onClick={createNewQuestion}>
                  <IconOld name="AddCircle" width={14} height={14} />
                  <span>
                    <Trans id="Create new" />
                  </span>
                </CreateNewQuestion>
              </CreateNewQuestionWrapper>
            </List>
          </div>
        ) : (
          <Placeholder>
            <div className="description">
              <span>
                <Trans id="No questions yet…" />
              </span>
              <span>
                <Trans id="Start creating questions!" />
              </span>
            </div>
            <img src={emptyState} alt={i18n._(t`No data found`)} width="216px" />
            <CreateFirstQuestion onClick={createNewQuestion}>
              <Trans id="Create first question" />
            </CreateFirstQuestion>
          </Placeholder>
        )}
        <Footer>
          <BackButton type="button" onClick={() => setCurrentSection(0)}>
            <IconOld name="ChevronBack" width={16} height={16} className="icon" />
            <span>{i18n._(t`Back`)}</span>
          </BackButton>
          {!!size(fields) && isDraft && (
            <PublishButton onClick={onPublish} type="button" disabled={isPublishDisabled}>
              <span>{i18n._(t`Publish`)}</span>
            </PublishButton>
          )}
          <div />
        </Footer>
      </div>
      {isCreateQuestionModalOpen && (
        <QuestionModal
          languageState={languageState}
          onClose={() => setIsCreateQuestionModalOpen(false)}
          onDelete={() => setIsCreateQuestionModalOpen(false)}
          onSubmit={createQuestion}
        />
      )}
      {isEditQuestionModalOpen && !isNil(indexItemToEdit) && (
        <QuestionModal
          languageState={languageState}
          defaultValues={itemToEdit}
          onClose={() => setIsEditQuestionModalOpen(false)}
          onDelete={() => {
            itemToEdit?.id && deleteSurveyQuestion(itemToEdit.id);
            remove(indexItemToEdit);
            setIsEditQuestionModalOpen(false);
            updateLastSavedTime();
          }}
          onDuplicate={(questionForm) => {
            duplicateQuestion(questionForm.id, indexItemToEdit + 1);
            update(indexItemToEdit, questionForm);
            setIsEditQuestionModalOpen(false);
          }}
          isEditMode
          onSubmit={(value: IQuestion) => {
            updateQuestion(value, indexItemToEdit);
          }}
        />
      )}
      {isPreviewModalVisible && (
        <PreviewQuestionModal
          defaultValues={fields[previewIndex]}
          languageState={languageState}
          onClose={() => setIsPreviewModalVisible(false)}
        />
      )}
    </Wrapper>
  );
};

export { Questions };
