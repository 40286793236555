import styled from 'styled-components';

import { COLORS } from '~/styles';

export const ImageWrapper = styled.div<{
  imgPadding?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    ${({ imgPadding }) => imgPadding && `padding: ${imgPadding};`}
  }
`;

export const PlaceholderText = styled.div`
  max-width: 300px;
  font-size: 14px;
  text-align: center;
  color: ${COLORS.PLACEHOLDER_LIGHT};
  margin-top: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: ${COLORS.WHITE};
`;

export const EmptyButtonWrapper = styled.div`
  margin-top: 20px;
`;

export const PlaceholderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
`;
