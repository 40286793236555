import React, { ChangeEvent, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import type { ITabSliderItem } from '~/components/TabSlider/types';

import { StatusLabel } from './components/StatusLabel';
import { useReview } from './hooks/useReview';

import routes from '~/constants/routes';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { checkModuleReviews } from '~/selectors/baseGetters';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { LogsTab } from '../tabs/Logs';
import { ParticipationTab } from '../tabs/Participation';
import { ResultsTab } from '../tabs/Results';
import { SetupTab } from '../tabs/Setup';

import type { IReviewDashboardCycleForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

const ContentWrapper = styled.div`
  margin: 0 auto;
`;

const BannerContainer = styled.div`
  max-width: 1000px;
  margin: 32px auto 0;
`;

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardCycleForm>;
  languageState: ILanguageStateReturn;
}

export const REVIEW_CYCLE_DASHBOARD_TAB_KEYS = {
  PARTICIPATION: 'participation',
  CALIBRATION: 'calibration',
  RESULTS: 'results',
  ANSWERS: 'answers',
  SETTINGS: 'settings',
  LOGS: 'logs',
};

const ReviewDashboardForm = ({
  formMethods,
  languageState: _languageState,
}: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const history = useHistory();
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const isModulePerformanceEnabled = useSelector(checkModuleReviews);
  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;

  const {
    isLoading,
    goToReviewCycles,
    onDelete,
    onEdit,
    onArchive,
    onUnarchive,
    onShareCalibrationInput,
    isAdmin,
    isCreator,
    isAllowToEdit,
    isAllowToDelete,
    isAllowToArchive,
    isAllowToUnarchive,
    isAllowToShareCalibrationInput,
    isCoach,
  } = useReview({
    formMethods,
    reviewId,
    showFetchFailureToast: false,
  });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  useEffect(() => {
    if (!isModulePerformanceEnabled) {
      history.push(routes.HOME);
    }

    // eslint-disable-next-line
  }, [isModulePerformanceEnabled, history]);

  const iconButtons = [
    isAllowToDelete && {
      icon: ICONS.DELETE_BIN,
      onClick: onDelete,
      isLoading,
      tooltip: i18n._(t`Delete`),
    },
    isAllowToArchive && {
      icon: ICONS.ARCHIVE,
      onClick: onArchive,
      isLoading,
      tooltip: i18n._(t`Archive`),
    },
    isAllowToUnarchive && {
      icon: ICONS.UNARCHIVE,
      onClick: onUnarchive,
      isLoading,
      tooltip: i18n._(t`Unarchive`),
    },
    isAllowToEdit && {
      icon: ICONS.EDIT_PENCIL,
      onClick: (_e: ChangeEvent<HTMLInputElement>) => onEdit(),
      isLoading,
      tooltip: i18n._(t`Edit`),
    },
  ].filter((i) => i) as unknown as HeaderIconButtons[];

  const TABS: ITabSliderItem[] = [
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.PARTICIPATION,
      label: t`Participation`,
      content: <ParticipationTab formMethods={formMethods} />,
    },
    /* skip in v1 */
    /* {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.CALIBRATION,
      label: t`Calibration`,
      content: <CalibrationTab />,
    },*/
    {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.RESULTS,
      label: t`Results`,
      content: <ResultsTab />,
    },
    /* skip in v1 */
    /* {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.ANSWERS,
      label: t`Answers`,
      content: <AnswersTab />,
    },*/
    (isAdmin || isCreator) && {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.SETTINGS,
      label: t`Setup`,
      content: (
        <SetupTab
          formMethods={formMethods}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          onEdit={onEdit}
          onShareCalibrationInput={onShareCalibrationInput}
          isAllowToArchive={isAllowToArchive}
          isAllowToUnarchive={isAllowToUnarchive}
          isAllowToEdit={isAllowToEdit}
          isAllowToShareCalibrationInput={isAllowToShareCalibrationInput}
        />
      ),
    },
    (isAdmin || isCreator) && {
      key: REVIEW_CYCLE_DASHBOARD_TAB_KEYS.LOGS,
      label: t`Activity log`,
      content: <LogsTab />,
    },
  ].filter((i) => i);

  return (
    <>
      <HeaderFocusMode
        title={getMultiLangString(nameMultiLang)}
        goBack={goToReviewCycles}
        iconButtons={iconButtons}
        status={<StatusLabel status={watch('status')} />}
        isBackIcon={true}
      />
      <ContentWrapper>
        <ShowSpinnerIfLoading loading={isLoading}>
          {!isCreator && isCoach && (
            <BannerContainer>
              <Banner
                type={BANNER_TYPES.INFO}
                title={i18n._(t`This is a company wide review`)}
                subTitle={i18n._(t`You can see the information from employees in your team.`)}
              />
            </BannerContainer>
          )}

          <TabSlider items={TABS} isHashInURL={true} maxWidth={'auto'} noPadding />
        </ShowSpinnerIfLoading>
      </ContentWrapper>
    </>
  );
};

export { ReviewDashboardForm };
