import React, { useLayoutEffect, useRef, useState } from 'react';

import { Trans } from '@lingui/macro';

import { IconOld } from '~/components/IconOld';

import { Flag } from './components/Flag';
import { Section } from './components/Section';
import {
  Animated,
  AnimatedTitle,
  Card,
  Container,
  Line,
  Lines,
  NextButton,
  PreviousButton,
  SectionContainer,
  Sections,
  Title,
  WhiteSpace,
  Wrapper,
} from './design';
import { type ITableOfContentsProps, SECTION_STATUS, SECTION_TYPE } from './types';

import { useKeyPress } from '~/hooks/useKeyPress';
import { COLORS } from '~/styles';

const TableOfContents = ({
  sections,
  currentSection,
  setCurrentSection,
  className,
  hideErrorState,
  noNextSection,
  nextSection,
  previousSection,
  isShowLastPage,
  setShowOutro,
  isShowOutro,
}: ITableOfContentsProps) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState(false);

  useKeyPress('Enter', nextSection);
  useKeyPress('Backspace', previousSection);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setHeight(ref?.current?.clientHeight ?? 0);
  });

  const isCollapsed = currentSection !== sections.length - 1 && !isHover;
  const isAllAnswered = sections.every((section) => section.status === SECTION_STATUS.DONE);

  return (
    <Container onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {currentSection !== 0 && (
        <PreviousButton isHidden={isCollapsed} onClick={previousSection}>
          <IconOld name="ChevronUp" width={24} height={24} className="icon" />
          <Trans>Previous</Trans>
        </PreviousButton>
      )}
      <SectionContainer ref={ref} className={className}>
        <WhiteSpace />
        <Sections>
          <Lines>
            {Array.from({ length: sections.length - 1 }).map((_, index) => {
              const topSection = sections[index];
              const bottomSection = sections[index + 1];
              let color: string | undefined;
              if (
                bottomSection.type !== SECTION_TYPE.PARENT &&
                (topSection.status === SECTION_STATUS.DONE ||
                  topSection.status === SECTION_STATUS.ERROR ||
                  (topSection.type === SECTION_TYPE.PARENT &&
                    topSection.status === SECTION_STATUS.CURRENT)) &&
                (bottomSection.status === SECTION_STATUS.DONE ||
                  bottomSection.status === SECTION_STATUS.ERROR ||
                  currentSection === index + 1)
              ) {
                color = 'var(--company-color)';
              } else if (bottomSection.type === SECTION_TYPE.PARENT) {
                color = 'inherit';
              }
              return <Line color={color} key={index} />;
            })}
            {isShowLastPage && <Line color={COLORS.COMPANY} />}
          </Lines>
          {sections.map((section, index) => (
            <Section
              section={section}
              parentHeight={height}
              total={sections.filter((section) => SECTION_TYPE.CHILD !== section.type).length}
              key={`${section.title}-${index}`}
              index={index}
              isCollapsed={isCollapsed}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
              hideErrorState={hideErrorState}
              {...section}
              isShowOutro={isShowOutro}
            />
          ))}
          {isShowLastPage && (
            <Wrapper
              onClick={() => setShowOutro && setShowOutro(true)}
              parentHeight={height}
              ref={ref}
            >
              <Card
                isCollapsed={isCollapsed}
                status={isShowOutro ? SECTION_STATUS.CURRENT : SECTION_STATUS.DONE}
                titleColor={'var(--text-hover)'}
              >
                <Flag status={isAllAnswered ? SECTION_STATUS.DONE : undefined} />
                <Animated>
                  <AnimatedTitle shouldAnimate={!isCollapsed}>
                    <Title bold={true}>
                      <span>
                        <Trans>{'Complete review'}</Trans>
                      </span>
                    </Title>
                  </AnimatedTitle>
                </Animated>
              </Card>
            </Wrapper>
          )}
        </Sections>
        <WhiteSpace />
      </SectionContainer>
      {!noNextSection && (
        <NextButton isHidden={isCollapsed} onClick={nextSection}>
          <IconOld name="ChevronDown" width={24} height={24} className="icon" />
          <Trans>Next</Trans>
        </NextButton>
      )}
    </Container>
  );
};

export { TableOfContents };
